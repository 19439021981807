import { useDispatch, useSelector } from "react-redux";
import FormStaySummary from "../../../components/formStaySummary/FormStaySummary";
import {
  reviewAddStay,
  updateAddStayReviewStatus,
} from "../../../store/feature/staysummary/staySummarySlice";
import {
  ENTER_STAY_DETAILS,
  LABEL_ADJ_CODE,
  LABEL_ARRIVAL_DATE,
  LABEL_BOOKING_SOURCE,
  LABEL_CONFO_NUMBER,
  LABEL_CORPORATE_ID,
  LABEL_CURRENCY,
  LABEL_GUEST_NAME,
  LABEL_MARKET_CODE,
  LABEL_MARKET_SEGMENT,
  LABEL_NO_PAY_REASON,
  LABEL_RATE_PLAN,
  LABEL_ROOM_NIGTHS,
  LABEL_ROOM_RATE_TAX_AMT,
  LABEL_TAX_INCLUDED,
  SS_ERROR_MSG_CONFO_REQUIRED,
  SS_ERROR_MSG_GUESTNAME_VALIDATION,
  SS_ERROR_MSG_GUESTNAME_REQUIRED,
  SS_GUESTNAME_PLACEHOLDER,
  SS_ERROR_MSG_ARRIVAL_REQUIRED,
  SS_ERROR_MSG_MARKETSEGMENT_REQUIRED,
  SS_ERROR_MSG_ROOM_TAX_AMOUNT_REQUIRED,
  SS_ERROR_MSG_RATEPLAN_REQUIRED,
  SS_ERROR_MSG_MARKETCODE_REQUIRED,
  SS_ERROR_MSG_ROOMNIGHTS_REQUIRED,
  SS_ERROR_MSG_ROOMRATE_REQUIRED,
  SS_ERROR_MSG_ROOMNIGHTS_INVALID,
  ERROR_MSG_VALIDATION_ALLOWED_NUMBERS,
  SS_ERROR_MSG_ONLY_ALPHA_NUMERIC,
  SS_ERROR_MSG_BOOKING_SOURCE_REQUIRED,
  SS_ERROR_MSG_ROOMNIGHTS_ARRIVAL_REQUIRED,
  SS_ERROR_MSG_MARKETCODE_INVALID,
  SS_ERROR_MSG_CONFO_VALIDATION_ACRS,
  SS_ERROR_MSG_CONFO_VALIDATION_NO_ACRS,
  STAY_ERROR_MAX_ROOM_RATE,
  STAY_ERROR_MAX_ROOM_NIGHTS_EXCEEDED,
  STAY_ERROR_MAX_NIGHTS,
  LABEL_BOOKING_DATE,
  SS_ERROR_MSG_BOOKING_REQUIRED,
  LABEL_ROOM_RATE_ADR,
  STAY_REVIEW_BTN_LABEL,
} from "../../../utils/constants";
import { useEffect, useMemo, useState } from "react";
import {
  convertObjToArray,
  differenceInDays,
  formatAmount,
  getNoPayList,
  rateTaxValidation,
  rateValidation,
  replaceAll,
} from "../../../utils/utils";
import {
  DEFAULT_STAY_ADJUSTMENT_CODE,
  NON_ACRS_DEFAULT_DATE,
  US_COUNTRY_CODE,
  GROUP_ROUTE_PATH,
  RESERVATION_CANCELED,
  GROUP_MARKET_SEGMENTS,
  TRANSIENT_MARKET_SEGMENTS,
} from "../../../utils/config";
import { useLocation, useParams } from "react-router-dom";

export default function AddStayDetails({ handleAddStayClose }) {
  const { propertyCode } = useParams();
  const { pathname } = useLocation();
  const {
    propertyDetails,
    masterData,
    payloadForAddStayReview,
    addStayReviewStatus,
    addStayReviewMode,
  } = useSelector((state) => state.staySummary);
  const { userInfo } = useSelector((state) => state.landing);
  const [taxIncluded, setTaxIncluded] = useState();
  const [saveErrorMessage, setSaveErrorMessage] = useState("");
  const [saveAPIError, setSaveAPIError] = useState("");
  const [triggerValidation, setTriggerValidation] = useState("");

  const [skipError, setSkipError] = useState(false);
  const [arrival, setArrival] = useState();
  const [marketPrefix, setMarketPrefix] = useState();
  const [noPayReason, setNoPayReason] = useState("");
  const [roomRateCtac, setRoomRateCtac] = useState("");
  const [roomRateTax, setRoomRateTax] = useState(0);
  const [roomNight, setRoomNight] = useState();
  const [disableSave, setDisableSave] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const dispatch = useDispatch();
  const maxNight = Number(propertyDetails?.maxNight);
  const hotelConversion = propertyDetails?.acrsConvDate
    ? new Date(propertyDetails?.acrsConvDate).toISOString().slice(0, 10)
    : "";
  const { countryCode } = propertyDetails;
  const isGroupBlock = pathname.includes(GROUP_ROUTE_PATH);

  useEffect(() => {
    setDisableSave(false);
    if (addStayReviewStatus.maxBrandRateWarning) {
      setSaveErrorMessage(STAY_ERROR_MAX_ROOM_RATE);
      setSkipError(true);
      setDisableSave(true);
    } else {
      setSaveErrorMessage("");
      setSkipError(false);
    }
    if (addStayReviewStatus.error && !addStayReviewStatus.maxBrandRateWarning) {
      setSaveAPIError(addStayReviewStatus.message);
      setDisableSave(true);
    } else {
      setSaveAPIError("");
    }
  }, [addStayReviewStatus]);

  useEffect(() => {
    if (addStayReviewMode) {
      dispatch(
        updateAddStayReviewStatus({
          maxBrandRateWarning: false,
          success: false,
          error: false,
          message: ``,
        })
      );
    }
  }, [addStayReviewMode]);

  useEffect(() => {
    setSaveErrorMessage("");
    setSaveAPIError("");
    setSkipError(false);
    setDisableSave(false);
  }, [arrival]);

  useEffect(() => {
    if (roomNight > maxNight) {
      setSkipError(true);
    } else if (roomNight > masterData?.maxRoomNightsAllowed) {
      setSkipError(!Object.keys(validationErrors).length ? true : false);
    } else {
      setSkipError(false);
    }
  }, [roomNight]);

  const validateRoomNight = (value) => {
    const pattern = /^[0-9]{1,4}$/g;
    let returnVal = true;
    if (pattern.test(value)) {
      if (noPayReason && noPayReason === RESERVATION_CANCELED) {
        returnVal = true;
      } else if (Number(value) > masterData?.maxRoomNightsAllowed) {
        returnVal = STAY_ERROR_MAX_ROOM_NIGHTS_EXCEEDED;
      } else if (Number(value) > maxNight) {
        returnVal = STAY_ERROR_MAX_NIGHTS;
      } else {
        if (arrival) {
          const today = new Date();
          const todayMinusDay = new Date(today.setDate(today.getDate() - 1));
          const dayDif = differenceInDays(arrival, todayMinusDay);
          if (value <= dayDif) {
            returnVal = true;
          } else {
            returnVal = SS_ERROR_MSG_ROOMNIGHTS_INVALID;
          }
        } else {
          returnVal = SS_ERROR_MSG_ROOMNIGHTS_ARRIVAL_REQUIRED;
        }
      }
    } else {
      returnVal = ERROR_MSG_VALIDATION_ALLOWED_NUMBERS;
    }
    return returnVal;
  };

  const isNonConvertedHotel = (() => {
    if (hotelConversion) {
      const hotelConversionInt = replaceAll(hotelConversion, "-");
      if (hotelConversion === NON_ACRS_DEFAULT_DATE) {
        return true;
      } else if (arrival) {
        const arrivalInt = replaceAll(arrival, "-");
        if (Number(arrivalInt) < Number(hotelConversionInt)) {
          return true;
        }
      }
    }
    return false;
  })();

  const config = useMemo(() => {
    const today = new Date();
    const todayMinusDay = new Date(today.setDate(today.getDate() - 1));
    const maxDate = todayMinusDay.toISOString().slice(0, 10);
    const todayMinusYear = new Date(today.setFullYear(today.getFullYear() - 1));
    const minDate = new Date(
      todayMinusYear.setDate(todayMinusYear.getDate() + 3)
    )
      .toISOString()
      .slice(0, 10);

    const noPayReasonsList = getNoPayList({
      orginalNoPay: "",
      adjRsn: "",
      fastPayInd: "",
      masterNoPayList: masterData.noPayReasons,
      isIPC: false,
      isGroupBlock,
    });

    const marketCodeKey = isGroupBlock
      ? GROUP_MARKET_SEGMENTS
      : TRANSIENT_MARKET_SEGMENTS;

    const marketSegOptions = convertObjToArray(
      masterData?.[marketCodeKey]
    ).sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    let configData = {
      form: {
        arrivalDate: {
          label: LABEL_ARRIVAL_DATE,
          type: "date",
          maxDate,
          minDate,
          width: 218,
          rules: {
            required: true,
            message: SS_ERROR_MSG_ARRIVAL_REQUIRED,
            validate: (value) => {
              setArrival(value);
            },
          },
        },
        guestName: {
          label: LABEL_GUEST_NAME,
          type: "text",
          placeholder: SS_GUESTNAME_PLACEHOLDER,
          className: "uppercase",
          maxLength: 20,
          width: 305,
          rules: {
            required: true,
            message: SS_ERROR_MSG_GUESTNAME_REQUIRED,
            validate: (value) => {
              if (value.length < 20) {
                return value.includes("/")
                  ? true
                  : SS_ERROR_MSG_GUESTNAME_VALIDATION;
              } else {
                return true;
              }
            },
          },
        },
        confirmationNumber: {
          label: LABEL_CONFO_NUMBER,
          type: "text",
          maxLength: 10,
          width: 170,
          className: "uppercase",
          rules: {
            required: true,
            message: SS_ERROR_MSG_CONFO_REQUIRED,
            validate: (value) => {
              const pattEightChar = /^[0-9a-zA-Z]{8,8}$/g;
              const pattTenChar = /^[0-9a-zA-Z]{10,10}$/g;
              if (
                arrival &&
                hotelConversion &&
                (hotelConversion === NON_ACRS_DEFAULT_DATE ||
                  Number(replaceAll(arrival, "-")) <
                    Number(replaceAll(hotelConversion, "-")))
              ) {
                return pattEightChar.test(value)
                  ? true
                  : SS_ERROR_MSG_CONFO_VALIDATION_ACRS;
              } else {
                return pattTenChar.test(value)
                  ? true
                  : SS_ERROR_MSG_CONFO_VALIDATION_NO_ACRS;
              }
            },
          },
        },
        marketPrefix: {
          label: LABEL_MARKET_SEGMENT,
          placeholder: "Select",
          type: "selectv2",
          options: marketSegOptions,
          fieldClassName: "full-width",
          width: 218,
          rules: {
            required: true,
            message: SS_ERROR_MSG_MARKETSEGMENT_REQUIRED,
            validate: (value) => {
              if (value?.length) {
                setMarketPrefix(value);
              } else {
                setMarketPrefix("");
              }
            },
          },
        },
        ratePlan: {
          label: LABEL_RATE_PLAN,
          className: "uppercase",
          type: "text",
          width: 218,
          rules: {
            required: true,
            message: SS_ERROR_MSG_RATEPLAN_REQUIRED,
            validate: (value) => {
              const pattern = /^[a-zA-Z0-9]*$/g;
              return pattern.test(value)
                ? true
                : SS_ERROR_MSG_ONLY_ALPHA_NUMERIC;
            },
          },
        },
        corporateId: {
          label: LABEL_CORPORATE_ID,
          defaultValue: "",
          className: "uppercase",
          type: "text",
          width: 218,
          rules: {
            required: false,
            validate: (value) => {
              const pattern = /^[a-zA-Z0-9]*$/g;
              return value.length
                ? pattern.test(value)
                  ? true
                  : SS_ERROR_MSG_ONLY_ALPHA_NUMERIC
                : true;
            },
          },
        },
        marketCode: {
          label: LABEL_MARKET_CODE,
          className: "uppercase",
          type: "text",
          maxLength: 8,
          width: 218,
          rules: {
            required: true,
            message: SS_ERROR_MSG_MARKETCODE_REQUIRED,
            validate: (value) => {
              const code = value.slice(0, 2).toUpperCase();
              if (masterData[marketCodeKey][code]) {
                return true;
              } else {
                return SS_ERROR_MSG_MARKETCODE_INVALID;
              }
            },
          },
        },
        noPayReason: {
          label: LABEL_NO_PAY_REASON,
          className: "uppercase",
          type: "selectv2",
          defaultValue: noPayReason,
          options: convertObjToArray(noPayReasonsList, "keyValue", true),
          width: 218,
          rules: {
            validate: (value) => {
              setNoPayReason(value);
            },
          },
        },
        adjustCode: {
          label: LABEL_ADJ_CODE,
          defaultValue: DEFAULT_STAY_ADJUSTMENT_CODE,
          type: "readonly",
          width: 218,
        },
        roomNights: {
          label: LABEL_ROOM_NIGTHS,
          type: "text",
          maxLength: 4,
          width: 218,
          rules: {
            required: true,
            message: SS_ERROR_MSG_ROOMNIGHTS_REQUIRED,
            validate: (value) => {
              setRoomNight(Number(value));
              return validateRoomNight(value);
            },
          },
        },
        roomRateCtac: {
          label: LABEL_ROOM_RATE_ADR,
          type: "text",
          defaultValue: roomRateCtac,
          width: 218,
          precision: masterData?.currencyAndPrecision?.propertyPrecision,
          rules: {
            required: true,
            message: SS_ERROR_MSG_ROOMRATE_REQUIRED,
            validate: (value) => {
              return rateValidation(value);
            },
          },
          onKeyup: (val) => {
            setRoomRateCtac(val);
          },
          onBlur: (val) => {
            const formatedValue = val.replace(/,/g, "");
            if (!isNaN(Number(formatedValue))) {
              let rate = formatAmount(
                formatedValue,
                masterData?.currencyAndPrecision?.propertyPrecision
              );
              setRoomRateCtac(rate);
            }
          },
        },
        propertyCurrency: {
          label: LABEL_CURRENCY,
          defaultValue: masterData?.currencyAndPrecision?.propertyCurrency,
          type: "readonly",
          width: 218,
        },
        bookingSource: {
          label: LABEL_BOOKING_SOURCE,
          className: "uppercase",
          defaultValue: propertyCode,
          type: "text",
          maxLength: 5,
          width: 218,
          rules: {
            required: true,
            message: SS_ERROR_MSG_BOOKING_SOURCE_REQUIRED,
            validate: (value) => {
              const pattern = /^[a-zA-Z0-9]{1,5}$/g;
              return pattern.test(value)
                ? true
                : SS_ERROR_MSG_ONLY_ALPHA_NUMERIC;
            },
          },
        },
        roomRateTax: {
          label: LABEL_ROOM_RATE_TAX_AMT,
          type: "text",
          defaultValue: roomRateTax,
          width: 218,
          fieldClassName: `${taxIncluded ? "" : "hide"}`,
          precision: masterData?.currencyAndPrecision?.propertyPrecision,
          onBlur: (val) => {
            const formatedValue = val.replace(/,/g, "");
            if (!isNaN(Number(formatedValue))) {
              let rate = formatAmount(
                formatedValue,
                masterData?.currencyAndPrecision?.propertyPrecision
              );
              setRoomRateTax(rate);
            }
          },
          onKeyup: (val) => {
            setRoomRateTax(val);
          },
          rules: {
            required: taxIncluded ? true : false,
            message: SS_ERROR_MSG_ROOM_TAX_AMOUNT_REQUIRED,
            validate: taxIncluded
              ? (value) => {
                  if (!roomRateCtac?.length) {
                    setTriggerValidation("roomRateCtac");
                  } else {
                    setTriggerValidation("");
                  }
                  return rateTaxValidation(value, roomRateCtac);
                }
              : () => {},
          },
        },
      },
    };
    if (countryCode !== US_COUNTRY_CODE && !isNonConvertedHotel) {
      configData.secondaryBlock = {};
      configData.secondaryBlock.taxIncluded = {
        label: LABEL_TAX_INCLUDED,
        type: "checkbox",
        fieldClassName: "tax_included",
        rules: {
          validate: (value) => {
            setTaxIncluded(value);
          },
        },
      };
    }

    if (marketPrefix?.length) {
      configData.form.ratePlan = {
        label: LABEL_RATE_PLAN,
        className: "uppercase",
        type: "text",
        width: 218,
        rules: {
          required: true,
          message: SS_ERROR_MSG_RATEPLAN_REQUIRED,
          validate: (value) => {
            const pattern = /^[a-zA-Z0-9]*$/g;
            return pattern.test(value) ? true : SS_ERROR_MSG_ONLY_ALPHA_NUMERIC;
          },
        },
      };
    }

    if (arrival && hotelConversion) {
      const arrivalInt = replaceAll(arrival, "-");
      const hotelConversionInt = replaceAll(hotelConversion, "-");
      if (
        hotelConversion === NON_ACRS_DEFAULT_DATE ||
        Number(arrivalInt) < Number(hotelConversionInt)
      ) {
        configData.form.marketPrefix.disabled = true;
        configData.form.ratePlan.disabled = true;
        configData.form.corporateId.disabled = true;

        configData.form.marketPrefix.rules.required = false;
        configData.form.marketPrefix.rules.validate = () => {};
        configData.form.ratePlan.rules.required = false;
        configData.form.ratePlan.rules.validate = () => {};

        configData.form.marketCode.disabled = false;
        configData.form.marketCode.rules.required = true;
      } else {
        configData.form.marketPrefix.disabled = false;
        configData.form.ratePlan.disabled = false;
        configData.form.corporateId.disabled = false;

        configData.form.marketPrefix.rules.required = true;
        configData.form.ratePlan.rules.required = true;

        configData.form.marketCode.disabled = true;
        configData.form.marketCode.rules.required = false;
        configData.form.marketCode.rules.validate = () => {};
      }
    }
    if (arrival) {
      configData.form.roomNights = {
        label: LABEL_ROOM_NIGTHS,
        type: "text",
        maxLength: 4,
        width: 218,
        rules: {
          required: true,
          message: SS_ERROR_MSG_ROOMNIGHTS_REQUIRED,
          validate: (value) => {
            setRoomNight(Number(value));
            return validateRoomNight(value);
          },
        },
      };
    }

    const bookingMaxDate = arrival?.length ? arrival : maxDate;
    configData.form.bookingDate = {
      label: LABEL_BOOKING_DATE,
      disabled: arrival?.length ? false : true,
      type: "date",
      maxDate: bookingMaxDate,
      width: 218,
      rules: {
        required: true,
        message: SS_ERROR_MSG_BOOKING_REQUIRED,
        validate: (value) => {
          return arrival && new Date(value) > new Date(arrival) ? false : true;
        },
      },
    };
    return configData;
  }, [
    taxIncluded,
    arrival,
    marketPrefix,
    noPayReason,
    roomRateCtac,
    roomRateTax,
  ]);

  const handleReview = (data) => {
    if (data.corporateId) {
      data.corporateId = data.corporateId.toUpperCase();
    }
    if (data.confirmationNumber) {
      data.confirmationNumber = data.confirmationNumber.toUpperCase();
    }
    if (data.guestName) {
      data.guestName = data.guestName.toUpperCase();
    }
    if (data.roomRateCtac) {
      data.roomRateCtac = formatAmount(
        data.roomRateCtac,
        masterData?.currencyAndPrecision?.propertyPrecision
      );
    }
    if (data.roomRateTax) {
      data.roomRateTax = formatAmount(
        data.roomRateTax,
        masterData?.currencyAndPrecision?.propertyPrecision
      );
    }
    if (data.bookingSource) {
      data.bookingSource = data.bookingSource.toUpperCase();
    }
    if (data.marketPrefix) {
      data.marketPrefix = data.marketPrefix.toUpperCase();
    }
    if (data.ratePlan) {
      data.ratePlan = data.ratePlan.toUpperCase();
    }
    if (data.marketCode) {
      data.marketCode = data.marketCode.toUpperCase();
    }
    dispatch(
      reviewAddStay({
        ...payloadForAddStayReview,
        ...data,
        ...{
          bookedCurrency: masterData?.currencyAndPrecision?.bookedCurrency,
          bookedPrecision: masterData?.currencyAndPrecision?.bookedPrecision,
          propertyPrecision:
            masterData?.currencyAndPrecision?.propertyPrecision,
          psIndicator: "",
          skipError: skipError,
          transientStay: pathname.includes(GROUP_ROUTE_PATH) ? false : true,
          stayAction: 1,
          userId: userInfo?.EID,
          userInitials: userInfo?.userInitials,
        },
      })
    );
  };

  const handleErrors = (errors) => {
    setValidationErrors(errors);
  };

  const handleIsDirty = (flag) => {
    setIsDirty(flag);
  };

  return (
    <div className="add-stay-enter-details">
      <h5 className="weight-700">{ENTER_STAY_DETAILS}</h5>
      <FormStaySummary
        config={config}
        ignoreEmpty={false}
        submitCallBack={handleReview}
        disableSubmitAction={disableSave}
        handleAddStayClose={handleAddStayClose}
        applyBtnTitle={STAY_REVIEW_BTN_LABEL}
        className={`modify-form-stay step-2-stay p-0`}
        mode="onChange"
        triggerValidation={triggerValidation}
        alertMessage={saveErrorMessage}
        errorMessage={saveAPIError}
        formErrorCallback={handleErrors}
        isDirtyCallback={handleIsDirty}
        acrs={isNonConvertedHotel}
      />
    </div>
  );
}
