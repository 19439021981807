import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { removeEmpty } from "../../utils/utils";
import FormField from "../formfield/FormField";
import {
  ACTION_BAR_BTN_NAME,
  STAY_BACK_TO_EDIT_BTN_LABEL,
} from "../../utils/constants";
import InfoBar from "../infobar/InfoBar";

const Error = ({ children }) => {
  return <p className="form-error font-12">{children}</p>;
};

export default function FormStaySummary({
  config = { form: {}, secondaryBlock: {} },
  submitCallBack,
  ignoreEmpty = true,
  className = "",
  applyBtnTitle,
  handleAddStayClose,
  hideCancel,
  mode = "onSubmit",
  alertMessage,
  errorMessage,
  disableSubmitAction,
  formErrorCallback,
  isValidCallback,
  isDirtyCallback,
  acrs,
  readOnly,
  type = "addstay",
  handleBackToEdit,
  enableReviewAction = false,
  extraParams = {
    isGroupBlock: false,
  },
  triggerValidation,
}) {
  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors, isValid, isDirty },
    watch,
    clearErrors,
    resetField,
    getValues,
  } = useForm({ mode: mode, reValidateMode: mode });
  const formRef = useRef(null);
  const [primaryConfig, setPrimaryConfig] = useState({});
  const [secondaryConfig, setSecondaryConfig] = useState({});
  const [dirty, setDirty] = useState(false);
  useEffect(() => {
    setPrimaryConfig(config.form);
    setSecondaryConfig(config.secondaryBlock);
  }, [config]);

  useEffect(() => {
    clearErrors("roomRateTax");
    resetField("roomRateTax", { defaultValue: "0" });
  }, [watch("taxIncluded")]);

  useEffect(() => {
    if (!extraParams.isGroupBlock) {
      if (acrs) {
        resetField("marketPrefix", {
          defaultValue: "",
        });
        resetField("ratePlan", {
          defaultValue: "",
        });
        resetField("corporateId", {
          defaultValue: "",
        });
      } else {
        resetField("marketCode", {
          defaultValue: "",
        });
      }
      if (type === "addstay") {
        resetField("bookingDate", {
          defaultValue: "",
        });
        const confirmationNumber = getValues("confirmationNumber");
        if (confirmationNumber?.length) {
          trigger("confirmationNumber");
        }
      }
      if (type === "editstay") {
        trigger("roomNights");
        //trigger();
      }
    }
  }, [watch("arrivalDate")]);

  useEffect(() => {
    if (type === "editstay") {
      trigger("arrivalDate");
    }
  }, [watch("roomNights")]);

  const validationField = (fieldName) => {
    trigger(fieldName);
  };

  useEffect(() => {
    if (triggerValidation) {
      validationField(triggerValidation);
    }
  }, [triggerValidation]);

  useEffect(() => {
    if (formErrorCallback) {
      formErrorCallback(errors);
    }
  }, [errors]);

  const formBuilder = (dynamicConfig, sectionClassName = "") => {
    return (
      dynamicConfig &&
      Object.keys(dynamicConfig).map((fieldName) => {
        const {
          rules,
          label,
          type = "",
          fieldClassName = "",
          disabled,
          defaultValue,
          presetion,
          divClassName,
          width,
          onCustomChange,
        } = dynamicConfig[fieldName];
        return (
          <>
            {fieldName === "hr" ? (
              <hr className="separator" />
            ) : (
              <section
                key={fieldName}
                className={`${sectionClassName} ${fieldClassName} ${type} ${
                  disabled ? "disabled" : ""
                } form-item form-item-summary`}
                style={width ? { width: `${width}px` } : {}}
              >
                <label className={rules?.required ? "required" : ""}>
                  {label}
                </label>
                <Controller
                  name={fieldName}
                  control={control}
                  rules={rules}
                  defaultValue={defaultValue}
                  render={({ field }) => (
                    <div
                      className={`${errors[fieldName] ? "error-border" : ""}  ${
                        divClassName || ""
                      }`}
                    >
                      <FormField
                        key={`field-${fieldName}`}
                        name={fieldName}
                        value={field.value}
                        isError={errors?.[fieldName]}
                        presetion={presetion}
                        onBlur={(val) => {
                          field.onBlur(val);
                          setDirty(true);
                        }}
                        onChange={(val) => {
                          clearErrors(fieldName);
                          field.onChange(val);
                          setDirty(true);
                          if (onCustomChange) {
                            onCustomChange(val);
                          }
                        }}
                        onKeyup={(val) => {
                          if (field.onKeyup) {
                            field.onKeyup(val);
                          }
                        }}
                        {...dynamicConfig[fieldName]}
                      />
                    </div>
                  )}
                />
                {errors[fieldName] && (
                  <Error>
                    {errors[fieldName]?.message
                      ? errors[fieldName]?.message
                      : rules?.message
                      ? rules?.message
                      : ""}
                  </Error>
                )}
              </section>
            )}
          </>
        );
      })
    );
  };

  const primaryFields = formBuilder(primaryConfig);
  const secondaryFields = formBuilder(secondaryConfig, "float-end");

  const onSubmit = (data) => {
    if (ignoreEmpty) {
      data = removeEmpty(data);
    }
    submitCallBack(data);
  };

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const isDisableSubmit = (() => {
    if (disableSubmitAction || !isValid || Object.keys(errors).length) {
      return true;
    } else {
      if (!enableReviewAction && !dirty) {
        return true;
      }
      return false;
    }
  })();

  useEffect(() => {
    if (isValidCallback) {
      isValidCallback(isValid);
    }
  }, [isValid]);

  useEffect(() => {
    if (isDirtyCallback) {
      isDirtyCallback(dirty);
    }
  }, [isDirty]);

  return (
    <Container className={className}>
      <form
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <div className="d-flex">
          <div className="flex-fill flex-box-row">{primaryFields}</div>
          <div className="flex-fill">{secondaryFields}</div>
        </div>
        {!readOnly ? (
          <>
            {!Object.keys(errors).length && alertMessage ? (
              <InfoBar
                variant="info"
                enableTimerClose={false}
                enableCloseButton={false}
              >
                {alertMessage}
              </InfoBar>
            ) : null}
            {!Object.keys(errors).length && errorMessage ? (
              <InfoBar
                variant="danger"
                enableTimerClose={false}
                enableCloseButton={false}
              >
                {errorMessage}
              </InfoBar>
            ) : null}
            <div className="flex-box-row flex-align-start">
              {enableReviewAction ? (
                <Button
                  variant="secondary"
                  className="me-4"
                  onClick={handleBackToEdit}
                >
                  {STAY_BACK_TO_EDIT_BTN_LABEL}
                </Button>
              ) : null}
              <Button
                className={
                  hideCancel ? "apply-btn-update" : "apply-btn-validate"
                }
                //disabled={isDisableSubmit}
                type="submit"
                variant={hideCancel ? "outline-primary" : "primary"}
              >
                {applyBtnTitle}
              </Button>
              {!hideCancel && (
                <Button
                  variant="link"
                  className="text-decoration-none"
                  onClick={handleAddStayClose}
                >
                  {ACTION_BAR_BTN_NAME.CANCEL}
                </Button>
              )}
            </div>
          </>
        ) : null}
      </form>
    </Container>
  );
}
