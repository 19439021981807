let API_PATH = "",
  AUTH_API_PATH,
  API_URL = {},
  RELATIVE_API_PATH = ``,
  RELATIVE_AUTH_PATH = ``;
if (process.env.REACT_APP_ENV === "dev-local") {
  //API_PATH = "http://localhost:9090/v1/ctac";
  //API_PATH = `http://ctac-property-portal-api-ram-intdev2.ose-dev39-vxbyr.cloud.marriott.com/v1/ctac`;

  //API_PATH = `http://aries.ctac-property-portal-api.ram-test34.dev.cld.marriott.com/v1/ctac`;
  //AUTH_API_PATH = `http://aries.ctac-property-portal-auth.ram-test34.dev.cld.marriott.com`;
  API_PATH = `http://aries.ctac-property-portal-api.ram-intdev4.dev.cld.marriott.com/v1/ctac`;
  AUTH_API_PATH = `http://aries.ctac-property-portal-auth.ram-intdev4.dev.cld.marriott.com`;

  // API_PATH = `http://ctac-property-portal-api-ram-intdev2.ose-dev39-vxbyr.cloud.marriott.com/v1/ctac`;
  // AUTH_API_PATH = `http://ctac-property-portal-auth-ram-intdev2.ose-dev39-vxbyr.cloud.marriott.com`;
} else if (process.env.REACT_APP_ENV === "local") {
  API_PATH = `/ctac`;
  AUTH_API_PATH = `http://localhost:3000`;
} else if (process.env.REACT_APP_ENV === "dev") {
  API_PATH = `${RELATIVE_API_PATH}/v1/ctac`;
  AUTH_API_PATH = `${RELATIVE_AUTH_PATH}`;
} else if (process.env.REACT_APP_ENV === "qa") {
  API_PATH = `${RELATIVE_API_PATH}/v1/ctac`;
  AUTH_API_PATH = `${RELATIVE_AUTH_PATH}`;
} else if (process.env.REACT_APP_ENV === "prod") {
  API_PATH = `${RELATIVE_API_PATH}/v1/ctac`;
  AUTH_API_PATH = `${RELATIVE_AUTH_PATH}`;
} else {
  API_PATH = "/ctac";
  AUTH_API_PATH = `${RELATIVE_AUTH_PATH}`;
}
const LOCAL_API_PATH = `http://localhost:3000/ctac`;
export const BASE_PATH = API_PATH;

if (process.env.REACT_APP_ENV === "local") {
  API_URL.GET_BATCH_ACTIVE_WINDOW = `${BASE_PATH}/propertyportal/batchwindow`;
  API_URL.GET_SESSION = `${AUTH_API_PATH}/token/getCreateuserSession.json`;
  API_URL.REFRESH_SESSION = `${AUTH_API_PATH}/token/refreshToken.json`;
  API_URL.GET_USER_DETAILS = `${BASE_PATH}/user/getUserDetail.json`;
  API_URL.GET_BATCH_OVERVIEW = `${BASE_PATH}/landing/batchDetails.json`;
  API_URL.GET_STAYSUMMARY_BATCH_OVERVIEW = `${BASE_PATH}/staysummary/summaryBatchOverview.json`;
  API_URL.GET_STAY_SUMMARY = `${BASE_PATH}/staysummary`;
  API_URL.GET_BATCH_DETAILS = `${BASE_PATH}/transientbatch/master-detail-data.json`;
  API_URL.GET_TRANSIENT_BATCH_DETAILS_EXPORT = `${BASE_PATH}/batchdetailsexport`;
  API_URL.PUT_BATCH_REVIEW_APPROVE = `${BASE_PATH}/batchdetails/updatestatus`;
  API_URL.VALIDATE_IATA = `${BASE_PATH}/propertyportal/iatadetails`;

  /* Transient Tax */
  API_URL.GET_TRANSIENT_TAX_DETAILS = `${BASE_PATH}/transienttax/transient-tax.json`;
  API_URL.GET_TRANSIENT_TAX_DETAILS_EXPORT = `${BASE_PATH}/transienttaxdetails/export`;
  API_URL.PUT_TTAX_UPDATESTATUS = `${BASE_PATH}/transienttaxdetails/updatestatus`;

  /* Transient Stay Summary */
  API_URL.GET_STAY_BATCH_DETAIL = `${BASE_PATH}/batchdetail`;
  API_URL.GET_STAY_SUMMARY_DETAILS = `${BASE_PATH}/transientstaysummery/details.json`;
  API_URL.GET_PROPERTY_DETAILS = `${BASE_PATH}/propertydetails.json`;
  API_URL.GET_STAY_SUMMARY_FILTER_OPTION = `${BASE_PATH}/transientstaysummery/overviewfilter.json`;
  API_URL.GET_MASTER_DATA = `${BASE_PATH}/transientstaysummery/masterdata.json`;
  API_URL.SAVE_ADD_STAY = `${BASE_PATH}/batchdetails/stays/addstay`;
  API_URL.REVIEW_ADD_STAY = `${BASE_PATH}/batchdetails/stays/reviewstay`;
  API_URL.REVIEW_EDIT_STAY = `${BASE_PATH}/batchdetails/stays/reviewstay`;
  API_URL.SAVE_EDIT_STAY = `${BASE_PATH}/batchdetails/stays/updatestay`;
  API_URL.EDIT_NOPAY = `${BASE_PATH}/batchdetails/stays/editrsncode`;
  API_URL.GET_SUMMARY_EXPORT = `${BASE_PATH}/batchdetails/stays/export`;

  /*Group Research and Audit */
  API_URL.GET_AUDITBLOCK = `${BASE_PATH}/audit/audit.json`;
  API_URL.GET_AUDIT_EXPORT = `${BASE_PATH}/groupaudit/events/export`;

  /* Group Block */
  API_URL.GET_GROUPBLOCK = `${BASE_PATH}/groupblock/events`;
  API_URL.GET_GROUPBLOCK_DETAILS = `${BASE_PATH}/groupblock/groupdetails.json`;
  API_URL.GET_GROUP_BATCH_DETAILS_EXPORT = `${BASE_PATH}/batchdetailsexport`;
  API_URL.REVIEW_ADJUSTMENT = `${BASE_PATH}/groupblock/events/adjustmentdetails`;
  API_URL.SAVE_ADJUSTMENT = `${BASE_PATH}/groupblock/events/saveadjustment`;
  API_URL.GET_DEFER_PAYMENT = `${BASE_PATH}/groupblock/events/@property/deferpayment/`;
  API_URL.SAVE_DEFER_PAYMENT = `${BASE_PATH}/groupblock/events/updatedeferpayment`;
  API_URL.SAVE_BLOCK_STATUS = `${BASE_PATH}/groupblock/events/updatenopay/`;
  API_URL.GROUP_BLOCK_EXPORT = `${BASE_PATH}/groupblock/eventbatches/export`;

  /* Admin */
  API_URL.GET_BROADCAST_MSG = `${LOCAL_API_PATH}/admin/broadcastmsg.json`;
  API_URL.SAVE_BROADCAST_MSG = `${LOCAL_API_PATH}/admin/broadcastmsg.json`;
} else {
  API_URL.GET_BATCH_ACTIVE_WINDOW = `${BASE_PATH}/propertyportal/batchwindow`;
  API_URL.GET_SESSION = `${AUTH_API_PATH}/token/getCreateuserSession`;
  API_URL.REFRESH_SESSION = `${AUTH_API_PATH}/token/refreshToken`;
  API_URL.GET_USER_DETAILS = `${BASE_PATH}/user/getUserDetail`;
  API_URL.GET_BATCH_OVERVIEW = `${BASE_PATH}/overview`;
  API_URL.GET_STAYSUMMARY_BATCH_OVERVIEW = `${BASE_PATH}/batchdetails/stays/overview`;
  API_URL.GET_STAY_SUMMARY = `${BASE_PATH}/staysummary`;
  API_URL.GET_BATCH_DETAILS = `${BASE_PATH}/batchdetails`;
  API_URL.GET_TRANSIENT_BATCH_DETAILS_EXPORT = `${BASE_PATH}/batchdetailsexport`;
  API_URL.PUT_BATCH_REVIEW_APPROVE = `${BASE_PATH}/batchdetails/updatestatus`;
  API_URL.VALIDATE_IATA = `${BASE_PATH}/propertyportal/iatadetails`;

  /* Transient Tax */
  API_URL.GET_TRANSIENT_TAX_DETAILS = `${BASE_PATH}/transienttaxdetails`;
  API_URL.GET_TRANSIENT_TAX_DETAILS_EXPORT = `${BASE_PATH}/transienttaxdetails/export`;
  API_URL.PUT_TTAX_UPDATESTATUS = `${BASE_PATH}/transienttaxdetails/updatestatus`;

  /* Transient Stay Summary */
  API_URL.GET_STAY_BATCH_DETAIL = `${BASE_PATH}/batchdetail`;
  API_URL.GET_STAY_SUMMARY_DETAILS = `${BASE_PATH}/batchdetails/stays`;
  API_URL.GET_PROPERTY_DETAILS = `${BASE_PATH}/propertyportal/propertydetails`;
  API_URL.GET_STAY_SUMMARY_FILTER_OPTION = `${BASE_PATH}/batchdetails/stays/overviewfilter`;
  API_URL.GET_MASTER_DATA = `${BASE_PATH}/batchdetails/stays/addstay/masterdata`;
  API_URL.SAVE_ADD_STAY = `${BASE_PATH}/batchdetails/stays/addstay`;
  API_URL.REVIEW_ADD_STAY = `${BASE_PATH}/batchdetails/stays/reviewstay`;
  API_URL.REVIEW_EDIT_STAY = `${BASE_PATH}/batchdetails/stays/reviewstay`;
  API_URL.SAVE_EDIT_STAY = `${BASE_PATH}/batchdetails/stays/updatestay`;
  API_URL.EDIT_NOPAY = `${BASE_PATH}/batchdetails/stays/editrsncode`;
  API_URL.GET_SUMMARY_EXPORT = `${BASE_PATH}/batchdetails/stays/export`;

  /*Group Research and Audit */
  API_URL.GET_AUDITBLOCK = `${BASE_PATH}/groupaudit/events`;
  API_URL.GET_AUDIT_EXPORT = `${BASE_PATH}/groupaudit/events/export`;

  /* Group Block */
  API_URL.GET_GROUPBLOCK = `${BASE_PATH}/groupblock/events`;
  API_URL.GET_GROUPBLOCK_DETAILS = `${BASE_PATH}/groupblock/events/`;
  API_URL.GET_GROUP_BATCH_DETAILS_EXPORT = `${BASE_PATH}/batchdetailsexport`;
  API_URL.REVIEW_ADJUSTMENT = `${BASE_PATH}/groupblock/events/adjustmentdetails`;
  API_URL.SAVE_ADJUSTMENT = `${BASE_PATH}/groupblock/events/saveadjustment`;
  API_URL.GET_DEFER_PAYMENT = `${BASE_PATH}/groupblock/events/@property/deferpayment`;
  API_URL.SAVE_DEFER_PAYMENT = `${BASE_PATH}/groupblock/events/updatedeferpayment`;
  API_URL.SAVE_BLOCK_STATUS = `${BASE_PATH}/groupblock/events/updatenopay/`;
  API_URL.GROUP_BLOCK_EXPORT = `${BASE_PATH}/groupblock/eventbatches/export`;

  /* Admin */
  API_URL.SAVE_BROADCAST_MSG = `${BASE_PATH}/propertyportal/messagesalerts/add`;
  API_URL.GET_BROADCAST_MSG = `${BASE_PATH}/overview/broadcastmsg`;
}

export { API_URL, AUTH_API_PATH };
