import React, { createContext, useContext, useReducer } from "react";

const AlertMsgContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    // Start - Group Block Screen
    case "CommNonCommShow":
      return {
        ...state,
        CommNonComm: { ...state.CommNonComm, ...action.payload, show: true },
      };
    case "CommNonCommHide":
      return {
        ...state,
        CommNonComm: { ...state.CommNonComm, show: false },
      };
    case "reviewApproveFailShow":
      return {
        ...state,
        reviewApproveFailContext: {
          ...state.reviewApproveFailContext,
          ...action.payload,
          show: true,
        },
      };
    case "reviewApproveFailHide":
      return {
        ...state,
        reviewApproveFailContext: {
          ...state.reviewApproveFailContext,
          show: false,
        },
      };
    case "reviewApproveSuccessShow":
      return {
        ...state,
        reviewApproveSuccessContext: {
          ...state.reviewApproveSuccessContext,
          ...action.payload,
          show: true,
        },
      };
    case "reviewApproveSuccessHide":
      return {
        ...state,
        reviewApproveSuccessContext: {
          ...state.reviewApproveSuccessContext,
          show: false,
        },
      };
    case "groupBatchListFailedShow":
      return {
        ...state,
        groupBatchListFailed: {
          ...state.groupBatchListFailed,
          ...action.payload,
          show: true,
        },
      };
    case "groupBatchListFailedHide":
      return {
        ...state,
        groupBatchListFailed: {
          ...state.groupBatchListFailed,
          show: false,
        },
      };
    case "groupScreenServerErrorShow":
      return {
        ...state,
        groupScreenServerError: {
          ...state.groupScreenServerError,
          show: true,
        },
      };
    case "groupScreenServerErrorHide":
      return {
        ...state,
        groupScreenServerError: {
          ...state.groupScreenServerError,
          show: false,
        },
      };
    case "groupTableInternalErrorShow":
      return {
        ...state,
        groupTableInternalError: {
          ...state.groupTableInternalError,
          show: true,
        },
      };
    case "groupTableInternalErrorHide":
      return {
        ...state,
        groupTableInternalError: {
          ...state.groupTableInternalError,
          show: false,
        },
      };
    case "groupAddAdjSuccessShow":
      return {
        ...state,
        groupAddAdjSuccess: {
          ...state.groupAddAdjSuccess,
          show: true,
        },
      };
    case "groupAddAdjSuccessHide":
      return {
        ...state,
        groupAddAdjSuccess: {
          ...state.groupAddAdjSuccess,
          show: false,
        },
      };

    // End - Group Block Screen

    // Start - Group Research & Audit screen
    case "groupResearchAuditServerErrorShow":
      return {
        ...state,
        groupResearchAuditServerError: {
          ...state.groupResearchAuditServerError,
          show: true,
        },
      };
    case "groupResearchAuditServerErrorHide":
      return {
        ...state,
        groupResearchAuditServerError: {
          ...state.groupResearchAuditServerError,
          show: false,
        },
      };

    case "groupResearchAuditInternalErrorShow":
      return {
        ...state,
        groupResearchAuditInternalError: {
          ...state.groupResearchAuditInternalError,
          show: true,
        },
      };
    case "groupResearchAuditInternalErrorHide":
      return {
        ...state,
        groupResearchAuditInternalError: {
          ...state.groupResearchAuditInternalError,
          show: false,
        },
      };

    // End - Group Research & Audit screen

    // Start - Stay Summery screen
    case "staySummeryServerErrorShow":
      return {
        ...state,
        staySummeryServerError: {
          ...state.staySummeryServerError,
          show: true,
        },
      };
    case "staySummeryServerErrorHide":
      return {
        ...state,
        staySummeryServerError: {
          ...state.staySummeryServerError,
          show: false,
        },
      };

    case "staySummeryBatchTableErrorShow":
      return {
        ...state,
        staySummeryBatchTableError: {
          ...state.staySummeryBatchTableError,
          show: true,
        },
      };
    case "staySummeryBatchTableErrorHide":
      return {
        ...state,
        staySummeryBatchTableError: {
          ...state.staySummeryBatchTableError,
          show: false,
        },
      };

    case "staySummeryAddStaySuccessShow":
      return {
        ...state,
        staySummeryAddStaySuccess: {
          ...state.staySummeryAddStaySuccess,
          show: true,
        },
      };
    case "staySummeryAddStaySuccessHide":
      return {
        ...state,
        staySummeryAddStaySuccess: {
          ...state.staySummeryAddStaySuccess,
          show: false,
        },
      };

    case "staySummeryEditStaySuccessShow":
      return {
        ...state,
        staySummeryEditStaySuccess: {
          ...state.staySummeryEditStaySuccess,
          ...action.payload,
          show: true,
        },
      };
    case "staySummeryEditStaySuccessHide":
      return {
        ...state,
        staySummeryEditStaySuccess: {
          ...state.staySummeryEditStaySuccess,
          show: false,
          message: "",
        },
      };

    case "staySummeryNoPaySaveSuccessShow":
      return {
        ...state,
        staySummeryNoPaySaveSuccess: {
          ...state.staySummeryNoPaySaveSuccess,
          ...action.payload,
          show: true,
        },
      };
    case "staySummeryNoPaySaveSuccessHide":
      return {
        ...state,
        staySummeryNoPaySaveSuccess: {
          ...state.staySummeryNoPaySaveSuccess,
          message: "",
          show: false,
        },
      };
    case "staySummeryNoPaySaveWarningShow":
      return {
        ...state,
        staySummeryNoPaySaveWarning: {
          ...state.staySummeryNoPaySaveWarning,
          ...action.payload,
          show: true,
        },
      };
    case "staySummeryNoPaySaveWarningHide":
      return {
        ...state,
        staySummeryNoPaySaveWarning: {
          ...state.staySummeryNoPaySaveWarning,
          message: "",
          show: false,
        },
      };

    case "voidNopaySuccessShow":
      return {
        ...state,
        voidSuccess: {
          ...state.voidSuccess,
          show: true,
        },
      };
    case "voidNopaySuccessHide":
      return {
        ...state,
        voidSuccess: {
          ...state.voidSuccess,
          show: false,
        },
      };
    case "staySummeryNoPaySaveErrorShow":
      return {
        ...state,
        staySummeryNoPaySaveError: {
          ...state.staySummeryNoPaySaveError,
          ...action.payload,
          show: true,
        },
      };
    case "staySummeryNoPaySaveErrorHide":
      return {
        ...state,
        staySummeryNoPaySaveError: {
          ...state.staySummeryNoPaySaveError,
          show: false,
          message: "",
        },
      };

    case "staySummeryDetailsInerenalErrorShow":
      return {
        ...state,
        staySummeryDetailsInerenalError: {
          ...state.staySummeryDetailsInerenalError,
          show: true,
        },
      };
    case "staySummeryDetailsInerenalErrorHide":
      return {
        ...state,
        staySummeryDetailsInerenalError: {
          ...state.staySummeryDetailsInerenalError,
          show: false,
        },
      };
    // End - Stay Summery screen

    // Start - Transient screen
    case "transServerErrorShow":
      return {
        ...state,
        transServerError: {
          ...state.transServerError,
          show: true,
        },
      };
    case "transServerErrorHide":
      return {
        ...state,
        transServerError: {
          ...state.transServerError,
          show: false,
        },
      };

    case "transReviewApproveFailShow":
      return {
        ...state,
        transReviewApproveFail: {
          ...state.transReviewApproveFail,
          ...action.payload,
          show: true,
        },
      };
    case "transReviewApproveFailHide":
      return {
        ...state,
        transReviewApproveFail: {
          ...state.transReviewApproveFail,
          show: false,
        },
      };
    case "transReviewApproveSuccessShow":
      return {
        ...state,
        transReviewApproveSuccess: {
          ...state.transReviewApproveSuccess,
          ...action.payload,
          show: true,
        },
      };
    case "transReviewApproveSuccessHide":
      return {
        ...state,
        transReviewApproveSuccess: {
          ...state.transReviewApproveSuccess,
          actionStatus: "",
          show: false,
        },
      };

    case "transTableInternalErrorShow":
      return {
        ...state,
        transTableInternalError: {
          ...state.transTableInternalError,
          show: true,
        },
      };
    case "transTableInternalErrorHide":
      return {
        ...state,
        transTableInternalError: {
          ...state.transTableInternalError,
          show: false,
        },
      };

    // End - Transient screen

    // Start - Transient Tax screen

    case "transTaxServerErrorShow":
      return {
        ...state,
        transTaxServerError: {
          ...state.transTaxServerError,
          show: true,
        },
      };
    case "transTaxServerErrorHide":
      return {
        ...state,
        transTaxServerError: {
          ...state.transTaxServerError,
          show: false,
        },
      };

    case "transTaxUpdateSuccessShow":
      return {
        ...state,
        transTaxUpdateSuccess: {
          ...state.transTaxUpdateSuccess,
          ...action.payload,
          show: true,
        },
      };
    case "transTaxUpdateSuccessHide":
      return {
        ...state,
        transTaxUpdateSuccess: {
          ...state.transTaxUpdateSuccess,
          selectedStatus: "",
          show: false,
        },
      };

    case "transTaxUpdateErrorShow":
      return {
        ...state,
        transTaxUpdateError: {
          ...state.transTaxUpdateError,
          show: true,
        },
      };
    case "transTaxUpdateErrorHide":
      return {
        ...state,
        transTaxUpdateError: {
          ...state.transTaxUpdateError,
          show: false,
        },
      };

    case "transTaxTableErrorShow":
      return {
        ...state,
        transTaxTableError: {
          ...state.transTaxTableError,
          show: true,
        },
      };
    case "transTaxTableErrorHide":
      return {
        ...state,
        transTaxTableError: {
          ...state.transTaxTableError,
          show: false,
        },
      };
    // End - Transient Tax screen

    default:
      return state;
  }
};

export default function AlertMsgProvider({ children }) {
  const [alertContextState, alertContextDispatch] = useReducer(reducer, {
    CommNonComm: {
      show: false,
      message: "",
      variant: "success",
    },
    reviewApproveFailContext: {
      show: false,
      message: "",
      variant: "danger",
    },
    reviewApproveSuccessContext: {
      show: false,
      variant: "success",
      userAction: "",
    },
    groupBatchListFailed: {
      variant: "danger",
      show: false,
    },
    groupScreenServerError: {
      variant: "danger",
      show: false,
    },
    groupTableInternalError: {
      variant: "danger",
      show: false,
    },
    groupAddAdjSuccess: {
      show: false,
      variant: "success",
    },
    groupResearchAuditServerError: {
      variant: "danger",
      show: false,
    },
    groupResearchAuditInternalError: {
      variant: "danger",
      show: false,
    },
    staySummeryServerError: {
      variant: "danger",
      show: false,
    },
    staySummeryBatchTableError: {
      variant: "danger",
      show: false,
    },
    staySummeryAddStaySuccess: {
      variant: "success",
      show: false,
    },
    staySummeryEditStaySuccess: {
      variant: "success",
      show: false,
      message: "",
    },
    staySummeryNoPaySaveSuccess: {
      variant: "success",
      show: false,
      message: "",
    },
    staySummeryNoPaySaveWarning: {
      variant: "info",
      show: false,
      message: "",
    },
    voidSuccess: {
      variant: "success",
      show: false,
    },
    staySummeryNoPaySaveError: {
      variant: "danger",
      show: false,
      message: "",
    },
    staySummeryDetailsInerenalError: {
      variant: "danger",
      show: false,
    },
    transServerError: {
      variant: "danger",
      show: false,
    },
    transReviewApproveFail: {
      show: false,
      message: "",
      variant: "danger",
    },
    transReviewApproveSuccess: {
      show: false,
      variant: "success",
      actionStatus: "",
    },
    transTableInternalError: {
      variant: "danger",
      show: false,
    },
    transTaxServerError: {
      variant: "danger",
      show: false,
    },
    transTaxUpdateSuccess: {
      variant: "success",
      show: false,
      selectedStatus: "",
    },
    transTaxUpdateError: {
      variant: "danger",
      show: false,
    },
    transTaxTableError: {
      variant: "danger",
      show: false,
    },
  });

  // Log the state whenever it changes
  // console.log("alertContextState:", alertContextState);

  return (
    <AlertMsgContext.Provider
      value={{
        alertContextState,
        alertContextDispatch,
      }}
    >
      {children}
    </AlertMsgContext.Provider>
  );
}

export function useAlertMsg() {
  const context = useContext(AlertMsgContext);
  if (!context) {
    throw new Error(`AlertMsgContext must be used
                          within a AlertMsgProvider`);
  }
  return context;
}
