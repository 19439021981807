export const CURRENCY_CONST_USD = "USD";
export const CURRENCY_CONST_PROPERTY = "PROPERTY";
/* Start: Landing config */

export const GROUP_EVENT_AUDIT_DAYS = 30;

export const MAX_CHAR_COUNT = 420;

/* End: Transient Batch config */

/* Start: Transient Batch config */

export const PAGINATION_SIZE_OPTION = [25, 50, 100, 150, 200];
export const BATCH_STATUS_TYPE = {
  REVIEW: "Ready for Review",
  PENDING: "Ready for Approval",
  APPROVED: "Approved",
  BILL: "Ready for Billing",
  FUND: "Fund CTAC OFB Invoice",
  WAIT: "Ready for Processing",
  SUSPEND: "Suspended by IPC",
  ALL: "All",
};
export const FILTER_WAIT = "WAIT";
export const FILTER_EXTRACTED = "EXTRACTED";
export const MONTH_NUMBER = {
  January: "1",
  February: "2",
  March: "3",
  April: "4",
  May: "5",
  June: "6",
  July: "7",
  August: "8",
  September: "9",
  October: "10",
  November: "11",
  December: "12",
};
export const MONTH_FILTER_OPTION = [
  "Select Month",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const STATUS_FILTER_OPTION = [
  "Ready for Review",
  "Ready for Approval",
  "Approved",
  "Ready for Billing",
  "Fund CTAC OFB Invoice",
  "Ready for Processing",
  "Suspended by IPC",
  "All",
];

export const TRANS_STATUS_FILTER_OPTION = [
  "Ready for Review",
  "Ready for Approval",
  "Approved",
  "Ready for Billing",
  "Fund CTAC OFB Invoice",
  "Ready for Processing",
  "All",
];

export const DEFAULT_REVIEW_FILTER = ["REVIEW", "PENDING"];

export const TB_DEFAULT_TABLE_PAYLOAD = {
  filterBy: {
    month: "",
    modIndicator: [],
    status: [...DEFAULT_REVIEW_FILTER],
    batchId: "",
  },
  sortBy: {
    sortColumn: "",
    sortValue: "",
  },
  pageNumber: 1,
  pageSize: "25",
};

export const MOD_FILTER_OPTION = {
  " ": "(BLANKS)",
  M: "MODIFIED",
};
export const FOLIO_FILTER_OPTION = {
  R: "Folio Not Found",
  O: "Open Folio",
  C: "Currency Mismatch",
};

export const GB_MOD_FILTER_OPTION = {
  " ": "(BLANKS)",
  A: "ADD",
  U: "UPDATED BY IPC",
  M: "MODIFIED BY USER",
};

export const GB_MOD_STATUS_TYPE = {
  " ": "(BLANKS)",
  A: "A - ADD",
  U: "U - UPDATED BY IPC",
  M: "M - MODIFIED BY USER",
};

export const TRANSIENT_VIEW_STAYS_LINK = "/transient/transientstaysummary/";

export const TB_STATUS_PENDING = "P";
export const TB_STATUS_APPROVE = "A";
export const AUTO_APPROVAL_ID = ["CTBPMS", "CTDBAA"];
/* End: Transient Batch config */

export const TT_DEFAULT_TABLE_PAYLOAD = {
  filterBy: {
    iata: "",
    confirmationNumber: "",
    arrivalFrom: "",
    arrivalTo: "",
    guestName: "",
    status: ["O"],
  },
  sortBy: {
    sortColumn: "arrivalDate",
    sortValue: "ASC",
  },
  pageNumber: 1,
  pageSize: "25",
};

export const USER_ROLE = {
  SUPER_ADMIN: "9CTA",
  IPC: "PIPC",
};

export const TT_TABLE_RESET_FILTERBY_PAYLOAD = {
  iata: "",
  confirmationNumber: "",
  arrivalFrom: "",
  arrivalTo: "",
  guestName: "",
  status: [],
};

export const TT_STATUS_TYPE = {
  O: "Open",
  P: "Pending",
  B: "Released to Batch",
  I: "Inactive",
  ALL: "All",
};

export const TT_STATUS_NAME = {
  OPEN: "Open",
  PENDING: "Pending",
};

export const TTAX_STATUS_PENDING = "O";
export const TTAX_STATUS_OPEN = "P";

export const TT_STATUS_FILTER_OPTION = [
  "Open",
  "Pending",
  "Released to Batch",
  "Inactive",
  "All",
];
export const transientTaxTextColor = {
  "<30 Days": { color: "#1C1C1C" },
  "30 - 59 Days": { color: "#3FEE1C" },
  "60 - 89 Days": { color: "#D0021B" },
  "90+ Days": { color: "#D0021B" },
};
/* End: Transient Tax config */

/* Start: Transiend Stay Summery config */

export const STAY_SUMMERY_DEFAULT_TABLE_PAYLOAD = {
  filterBy: {
    iataNumber: "",
    confoNumber: "",
    marketSegment: [],
    noPayReason: [],
    folioIndicator: [],
    invoiceIndicator: "",
    guestName: "",
    arrivalDate: "",
    adjustCode: [],
    modIndicator: [],
    marketCode: "",
  },
  sortBy: {
    sortColumn: "",
    sortValue: "",
  },
  batchNum1: "",
  batchNum2: "",
  pageNumber: 1,
  pageSize: "25",
  currencyType: "property",
};

export const ADD_STAY_SAVE_RESET = {
  success: false,
  error: false,
  taxError: false,
  maxBrandRateWarning: false,
  message: "",
};

export const ADD_STAY_REVIEW_RESET = {
  success: false,
  error: false,
  taxError: false,
  maxBrandRateWarning: false,
  message: "",
};

export const EDIT_STAY_SAVE_RESET = {
  success: false,
  error: false,
  taxError: false,
  maxBrandRateWarning: false,
  message: "",
  warning: false,
};

export const STAY_SUMMERY_PS = "Ps";
export const YES_KEY = "Y";
export const NO_KEY = "N";
export const INVOICE_INDICATOR_KEY = "invoiceIndicator";
export const DEFAULT_STAY_ADJUSTMENT_CODE = "MADD";
export const INACTIVE_IATA_INDICATOR = "X";

export const NON_ACRS_DEFAULT_DATE = "0001-01-01";

export const RESERVATION_CANCELED = "CXLD";
export const VOID_RESERVATION = "VOID";

export const US_COUNTRY_CODE = "US";

/* End: Transiend Stay Summery config */

export const USER_ACCESS_LEVEL = {
  READ_ONLY: 1,
  REVIEW_ONLY: 2,
  REVIEW_AND_APPROVE: 3,
};

export const CTAC_SUPPORT_LINK =
  "https://marriott.service-now.com/msp?id=msp_record_producer&sys_id=9fab39e2d7532100a9ad1e173e24d484&u_support_category=application_support&application_service=6bd03288dba527400fa271fa8c961931&u_portal_name=CTACHELP-DESK-Support&readonly=true&u_visible=true&u_portal_category=aa188941dbc0c0541048715a8c9619f7&u_visibility=false";
export const ONYX_LINK = "https://portal.onyxcentersource.com/";
export const GRA_LINK =
  "https://mgscloud.marriott.com/common/sales-mktg-and-rev-mgmt/intermediary-partners/group-intermediaries/centralized-group-commission-program/program-procedures";

/*Audit - start */
let tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
let toDate = new Date(new Date().setDate(new Date().getDate() + 31));

export const GA_DEFAULT_TABLE_PAYLOAD = {
  filterBy: {
    eventStartDateFrom: tomorrow.toISOString().slice(0, 10),
    eventStartDateTo: toDate.toISOString().slice(0, 10),
    status: [],
    groupBlockName: "",
    groupBlockCode: "",
    suspsnseInd: [],
    fpIndicator: [],
    actIndicator: [],
    comIndicator: [],
    groupIataNumber: "",
    quoteNumber: "",
    blankIATA: false,
  },
  sortBy: {
    sortColumn: "",
    sortValue: "",
  },
  pageNumber: 1,
  pageSize: "25",
};
export const GA_SUS_FILTER_OPTIONS = [" ", "S"];
export const GA_ACT_FILTER_OPTIONS = [" ", "A"];
export const GA_COM_FILTER_OPTIONS = [" ", "Y"];
export const GA_PAY_FILTER_OPTIONS = [" ", "Y"];
export const GA_STATUS_FILTER_OPTIONS = [
  "No Activity",
  "Hotel Review",
  "Pending Billing",
  "Pending Funding",
  "Waiting for Processing",
  "Processed or Paid",
  "Partially Processed",
  "All",
];

export const GA_STATUS_COLOR_OPTIONS = ["No Activity", "Processed or Paid"];

export const STATUS_PROCERSSED_PAID = "Processed or Paid";

export const GA_STATUS_TYPE = {
  N: "No Activity",
  H: "Hotel Review",
  B: "Pending Billing",
  F: "Pending Funding",
  W: "Waiting for Processing",
  P: "Processed or Paid",
  PP: "Partially Processed",
  ALL: "All",
};

/*Audit - end */

export const GB_DEFAULT_TABLE_PAYLOAD = {
  type: "GROUPBATCH",
  filterBy: {
    modIndicator: [],
    status: [],
    groupBlockCode: "",
    groupIataNumber: "",
    quoteNumber: "",
    eventStartDateFrom: "",
    eventStartDateTo: "",
    groupBlockName: "",
    guestName: "",
    groupBlockId: "",
  },
  sortBy: {
    sortColumn: "",
    sortValue: "",
  },
  pageNumber: 1,
  pageSize: "25",
  currencyType: "property",
};

export const GB_BATCH_DEFAULT_TABLE_PAYLOAD = {
  filterBy: {
    status: [],
    modIndicator: [],
  },
  sortBy: {
    sortColumn: "",
    sortValue: "",
  },
  pageNumber: 1,
  pageSize: "25",
  currencyType: "property",
};
export const GROUP_VIEW_STAYS_LINK = "/groupblock/groupstaysummary/";

export const USER_LOCATION = {
  MARHQ: "MARHQ",
  OMARC: "OMARC",
};
export const BLANK_KEY = "BLANK";
export const BLANKS_KEY = "(BLANKS)";

export const ADJUSTMENT_REASON_OPTIONS = {
  RBAD: "Rebate",
  APAD: "Advance Payment",
  CPAD: "Comp Room",
  PKAD: "Package",
};

export const ADJ_REASON_RBAD = "RBAD";
export const ADJ_REASON_PKAD = "PKAD";
export const ADJ_REASON_CPAD = "CPAD";
export const ADJ_REASON_APAD = "APAD";

export const ADJ_REASON_CMAD = "CMAD";
export const ADJ_REASON_GRAD = "GRAD";

export const ADJ_REASON_NMNT = "NMNT";

export const ADJ_REASON_NOPAY_AND_VOID = [
  "RBAD",
  "PKAD",
  "CPAD",
  "APAD",
  "CMAD",
  "GRAD",
];

export const STANDARD_NOPAY_LIST = {
  BLANK: "",
  COMP: "",
  CXLD: "",
  MCHK: "",
  MMPP: "",
  MTAX: "",
  NCMS: "",
  NSHW: "",
  PPCM: "",
  VCHR: "",
};
export const FSPD_NOPAY_LIST = {
  FSPD: "",
  CXLD: "",
  NSHW: "",
  COMP: "",
  NCMS: "",
};
export const NMNT_ADJ_NOPAY_LIST = { NCMS: "", CXLD: "", NSHW: "" };
export const STSTEM_ASSIGNED_NOPAY_LIST = { CXLD: "", NSHW: "" };
export const SYSTEM_ASSIGNED_NOPAY_CODES = [
  "ERRR",
  "NCRO",
  "NCWW",
  "NCCM",
  "NCBV",
  "NCCT",
  "NCMO",
];

export const GINC_NOPAY_LIST = { CXLD: "", NSHW: "", NCMS: "" };
export const BINC_NOPAY_LIST = { CXLD: "", NSHW: "", NCMS: "" };

export const BINC_SYSTEM_ASSIGNED_NOPAY_CODES = ["BINC"];
export const GINC_SYSTEM_ASSIGNED_NOPAY_CODES = ["GINC"];

export const OTHER_SYSTEM_ASSIGNED_CODES = [
  "BLOK",
  "CCFR",
  "CCNC",
  "CCPR",
  "CPRV",
  "CXPT",
  "INTA",
  "NCMN",
  "NPRV",
  "NTFD",
  "PPBT",
  "PPGT",
  "PTBA",
  "SUSI",
];

export const ADJUSTMENT_REASON_SHORT_DESC = {
  RBAD: "RA",
  PKAD: "PA",
  CPAD: "CP",
  APAD: "AD",
};

export const ADJUSTMENT_NO_PAY_REASON = {
  "": "",
  MCHK: "Paid on Property",
  MMPP: "Comm Deducted from Master/Dir Bill",
  NCMS: "Non-Commissionable",
  PPCM: "Comm Deducted from Prepmt",
};

export const DEFER_PAYMENT_OPTIONS = { Yes: "Y", No: "N" };

export const BOOLEAN = { Y: "Yes", N: "No" };

export const INSIDE_BLOCK_COM_NON_COMMISSIONABLE_OPTIONS = {
  "": "Commissionable",
  NCMS: "Non-Commissionable",
  MCHK: "Paid on Property",
  MMPP: "Comm Deducted from Master/Dir Bill",
};

export const INSIDE_BLOCK_NON_COMMISSIONABLE_OPTIONS = {
  NCMS: "Non-Commissionable",
  "": "Commissionable",
  MCHK: "Paid on Property",
  MMPP: "Comm Deducted from Master/Dir Bill",
};

export const COMM_NON_COMM_KEY = {
  Commissionable: "",
  "Non-Commissionable": "NCMS",
};

export const COMMISSIONABLE_KEY = "Commissionable";
export const NON_COMMISSIONABLE_KEY = "Non-Commissionable";

export const GROUP_ROUTE_PATH = "groupblock";

export const MAX_ADJ_ROOM_LIMIT = 32767;
export const MAX_ADJ_COM_AMT_LIMIT = 99999.99;
export const GRA_TABLE_NAME = "GRA-Main-Table";
export const READONLY = "readonly";

export const REVIEW_STAY_ACTION_DETAILS = {
  iataNumber: "",
  modIndicator: "",
  arrivalDate: "",
  guestName: "",
  confirmationNumber: "",
  roomNights: "",
  propertyCurrency: "",
  roomRate: "",
  roomRateTax: "",
  marketPrefix: "",
  ratePlan: "",
  corporateId: "",
  marketCode: "",
  noPayReason: "",
  grpUnpaidCode: "",
  adjustCode: "",
  roomRevenue: "",
  propertyAmount: "",
  commTax: "",
  folioIndicator: "",
  propertyRatePercentage: "",
  commTaxPercentage: "",
  psIndicator: "",
  shareIndicator: "",
  contiguosIndicator: "",
  invoiceIndicator: "",
  bookingDate: "",
  bookingSource: "",
  taName: "",
};

export const GROUP_MARKET_SEGMENTS = "groupMarketSegments";
export const TRANSIENT_MARKET_SEGMENTS = "transientMarketSegments";
