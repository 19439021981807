import React from "react";
import { LANDING_LABELS } from "../../utils/constants";

export default function Footer() {
  return (
    <footer className="bg-light w-100">
      <div className="footer_section p-4 pb-1 app_container">
        <p>Learn more about Marriott’s compensation programs:</p>
        <ul>
          <li>
            <a
              target="_blank"
              href="https://mgscloud.marriott.com/common/business-resources/business-applications/ctac"
              rel="noreferrer"
            >
              Marriott Centralized Commission Program
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://mgscloud.marriott.com/mgs/marrdocs/mgs/common/salesmktgrevmgmt/intermediarypartners/commissions/managinglocalcommissionsviamtax.pdf"
              rel="noreferrer"
            >
              Managing Taxable Commissions
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://mgscloud.marriott.com/mgs/marrdocs/mgs/nalo+franchisee/financeaccounting/training/mirs/mirshowtoreadandretrieveyourmirsinvoice.docx"
              rel="noreferrer"
            >
              OFB Invoices
            </a>
          </li>
          <li>
            Access CTAC Registers via{" "}
            <a
              target="_blank"
              href="http://ondemandcnp.marriott.com/"
              rel="noreferrer"
            >
              OnDemand
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://mgscloud.marriott.com/common/sales-mktg-and-rev-mgmt/intermediary-partners/centralized-commissions/faq.html"
              rel="noreferrer"
            >
              {LANDING_LABELS.FAQ}
            </a>
          </li>
        </ul>
        <p className="text-center p-0 m-0 copyright_text ">
          Copyright © 2024 Marriott International. MARRIOTT CONFIDENTIAL AND
          PROPRIETARY INFORMATION
        </p>
      </div>
    </footer>
  );
}
