import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import {
  FILTER_LABEL_APPLY,
  FILTER_LABEL_CANCEL_ALL,
} from "../../utils/constants";
import { removeEmpty } from "../../utils/utils";
import FormField from "../formfield/FormField";

const ErrorMsg = ({ children }) => {
  return <p className="form-error">{children}</p>;
};

export default function Form({
  config = { form: {} },
  handleChange,
  submitCallBack,
  ignoreEmpty = true,
  className = "",
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm({ mode: "onChange" });
  const formRef = useRef(null);
  const [primaryConfig, setPrimaryConfig] = useState({});
  const [secondaryConfig, setSecondaryConfig] = useState({});
  useEffect(() => {
    setPrimaryConfig(config.form);
    setSecondaryConfig(config.secondaryBlock);
  }, [config]);

  const formBuilder = (dynamicConfig, sectionClassName = "") => {
    return (
      dynamicConfig &&
      Object.keys(dynamicConfig).map((fieldName) => {
        const {
          rules,
          defaultValue,
          label,
          type = "",
          fieldClassName = "",
          fieldWidth,
          divClassName,
        } = dynamicConfig[fieldName];
        return (
          <section
            key={fieldName}
            style={{ width: fieldWidth }}
            className={`${sectionClassName} ${fieldClassName} ${type} my-3 form-item`}
          >
            <label>{label}</label>
            <Controller
              name={fieldName}
              control={control}
              rules={rules}
              defaultValue={defaultValue}
              render={({ field }) => (
                <div
                  className={`${errors[fieldName] ? "error-border" : ""}  ${
                    divClassName || ""
                  }`}
                >
                  <FormField
                    key={`field-${fieldName}`}
                    name={fieldName}
                    value={field.value}
                    isError={errors?.[fieldName]}
                    onBlur={(val) => {
                      if (rules?.blurMode) {
                        field.onChange(val);
                      }
                    }}
                    onChange={(val) => {
                      if (rules?.blurMode) {
                        errors[fieldName] && clearErrors(fieldName);
                      } else {
                        if (
                          type !== "text" &&
                          typeof val !== "boolean" &&
                          val?.includes("All")
                        ) {
                          val = ["All"];
                        } else if (
                          type !== "text" &&
                          typeof val !== "boolean" &&
                          val?.includes("ALL")
                        ) {
                          val = ["ALL"];
                        }
                        field.onChange(val);
                      }
                    }}
                    {...dynamicConfig[fieldName]}
                  />
                </div>
              )}
            />
            {errors[fieldName] && (
              <ErrorMsg>
                {errors[fieldName]?.message
                  ? errors[fieldName]?.message
                  : rules.message}
              </ErrorMsg>
            )}
          </section>
        );
      })
    );
  };

  const primaryFields = formBuilder(primaryConfig);
  const secondaryFields = formBuilder(secondaryConfig, "float-end");

  const handleReset = () => {
    const mergedObj = { ...primaryConfig, ...secondaryConfig };
    let resetObj = {};
    for (const key in mergedObj) {
      resetObj[key] = "";
    }
    reset(resetObj);
    submitCallBack();
  };

  const onSubmit = (data) => {
    if (ignoreEmpty) {
      data = removeEmpty(data);
    }
    submitCallBack(data);
  };

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <Container fluid className={className}>
      <form
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <div className="d-flex">
          <div className="flex-fill flex-box-row">{primaryFields}</div>
          <div className="flex-fill">{secondaryFields}</div>
        </div>
        <div className="flex-box-row flex-align-end">
          <Button
            onClick={handleReset}
            variant="link"
            className="clear-all-btn"
          >
            {FILTER_LABEL_CANCEL_ALL}
          </Button>
          <Button
            className="apply-btn float-end"
            disabled={Object.keys(errors).length}
            type="submit"
            variant="primary"
          >
            {FILTER_LABEL_APPLY}
          </Button>
        </div>
      </form>
    </Container>
  );
}
