import React, { useEffect, useRef, useState } from "react";
import { BLANKS_LABEL } from "../../utils/constants";

const MultiSelect = ({
  id,
  onChange,
  placeholder = "",
  options = [],
  defaultValue = [],
  width,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const menuBtnRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (e) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      !menuBtnRef.current.contains(e.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (defaultValue?.includes("All")) {
      setSelectedOptions(options);
    } else {
      setSelectedOptions(defaultValue);
    }
  }, [defaultValue]);

  const handleOptionChange = (event) => {
    const optionId = event.target.value;
    const isChecked = event.target.checked;
    let selected = [];
    if (isChecked && optionId === "All") {
      selected = options;
    } else if (isChecked) {
      selected = [...selectedOptions, optionId];
    } else if (optionId === "All") {
      selected = [];
    } else {
      selected = selectedOptions.filter((id) => id !== optionId);
      if (selectedOptions.includes("All")) {
        selected = selected.filter((id) => id !== "All");
      }
    }
    setSelectedOptions(selected);
    onChange(selected);
  };
  return (
    <div className="multi-select" style={{ width: width }} id={id}>
      <div className={`dropdown ${isOpen ? "show" : ""}`}>
        <button
          ref={menuBtnRef}
          style={{ width: "100%" }}
          className="btn btn-select dropdown-toggle"
          type="button"
          id="multiSelectDropdown"
          onClick={toggleDropdown}
        >
          <span>
            {placeholder}
            <span
              className={`count-circle ${
                selectedOptions?.length ? "blue" : ""
              }`}
            >
              <label className="count">{selectedOptions?.length}</label>
            </span>
          </span>
        </button>
        {isOpen ? (
          <div
            ref={menuRef}
            className={`dropdown-menu ${isOpen ? "show" : ""}`}
            aria-labelledby="multiSelectDropdown"
          >
            {options.map((option) => (
              <div
                key={option}
                className={`drop-box  ${
                  option === "" ||
                  option === "BLANKS" ||
                  option === "blank" ||
                  option === " "
                    ? "blank_option"
                    : ""
                }`}
              >
                <input
                  key={`field-item-${option}`}
                  type="checkbox"
                  id={option === " " ? BLANKS_LABEL : option || BLANKS_LABEL}
                  checked={selectedOptions.includes(option)}
                  onChange={handleOptionChange}
                  value={option}
                />
                <label
                  className="ps-1"
                  key={option}
                  htmlFor={
                    option === " " ? BLANKS_LABEL : option || BLANKS_LABEL
                  }
                >
                  {option === " " ? BLANKS_LABEL : option || BLANKS_LABEL}
                </label>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MultiSelect;
